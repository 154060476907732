import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type ButtonProps = {
  className?: string,
  type: 'submit' | 'reset' | 'button',
  size?: 'sm' | 'md' | 'lg' | 'xl',
  icon?: ReactNode,
  styleType?: 'solid' | 'outline' | 'underline',
  text: string | ReactNode,
} & React.ComponentPropsWithoutRef<'button'>;

const Button = ({
  type,
  disabled,
  size = 'md',
  className,
  icon,
  text,
  styleType = 'solid',
  ...props
}: ButtonProps) => {
  const klasses = twMerge(
    'whitespace-nowrap max-lg:w-full min-w-[88px] rounded-full text-blue-100 py-4 px-6 grid grid-flow-col place-content-center gap-x-2 text-base items-center',
    (!disabled && styleType === 'solid') && 'bg-green-100 hover:bg-gradient-to-r hover:from-green-100 hover:to-green-200 focus:outline-none focus:ring-[3px] focus:ring-green-30 focus:ring-offset-0 active:ring-0 active:bg-gradient-to-r active:from-green-200 active:to-green-200',
    (!disabled && styleType === 'outline') && 'py-2 border-2 border-green-100 hover:border-2 hover:border-green-60 hover:outline-none focus:outline-none focus:ring-[3px] focus:ring-green-30 focus:ring-offset-0 active:border-green-200 active:outline-none active:ring-0',
    (!disabled && styleType === 'underline' && !!icon) && '!p-0 min-w-min !h-6 border-b-2 border-green-60 rounded-none hover:border-teal-60',
    (!disabled && styleType === 'underline') && '!p-0 min-w-min !h-6 border-b-2 border-green-60 rounded-none hover:border-teal-60',
    (disabled && styleType === 'outline') && 'py-2 border-2 cursor-default border-green-20 text-grey-500',
    (disabled && styleType === 'underline') && '!p-0 cursor-default decoration-[2px] underline underline-offset-[6px] decoration-green-20 text-grey-500',
    (disabled && styleType === 'solid') && 'cursor-default bg-green-20 text-opacity-40',

    size === 'sm' && 'py-2 px-4 text-sm',
    size === 'md' && 'py-3 px-4',
    size === 'lg' && 'py-4 px-6 font-museo-700',
    className,
  );

  return (
    <button
      type={type} // eslint-disable-line react/button-has-type
      className={klasses}
      disabled={disabled}
      {...props}
    >
      {text}
      {icon}
    </button>
  );
};

export default Button;
