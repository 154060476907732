import { Dispatch, SetStateAction } from 'react';
import * as yup from 'yup';

type SetPasswordValidationErrors = Dispatch<SetStateAction<{
  text: string;
  pass: boolean;
}[]>> | undefined;

const nameSchema = {
  name: yup
    .string()
    .trim()
    .min(1)
    .required('Full name is required'),
};

export const emailSchema = {
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
};

const passwordSchema = (setPasswordValidationErrors: SetPasswordValidationErrors) => ({
  password: yup
    .string()
    .test('passwordValidator', 'Your entered password is too weak!', (value) => {
      if (!setPasswordValidationErrors) return false;
      const valueToCheck = value || '';

      const hasMinimumLength = valueToCheck.length >= 8;
      const hasNumbers = /[0-9]/.test(valueToCheck);
      const hasSymbol = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/.test(valueToCheck);
      const hasWhitespaces = /\s/.test(valueToCheck);
      const hasUppercaseLetter = /[A-Z]/.test(valueToCheck);
      const hasLowercaseLetter = /[a-z]/.test(valueToCheck);

      const allPass = hasMinimumLength
        && hasNumbers && hasSymbol && hasUppercaseLetter && hasLowercaseLetter;

      setPasswordValidationErrors([
        { text: 'At least 8 characters', pass: hasMinimumLength },
        { text: 'Contains a number', pass: hasNumbers },
        { text: 'Contains a special character:\n ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ ` + =', pass: hasSymbol },
        { text: 'Contains an uppercase letter', pass: hasUppercaseLetter },
        { text: 'Contains an lowercase letter', pass: hasLowercaseLetter },
        { text: 'No white spaces allowed', pass: !hasWhitespaces },
      ]);

      if (allPass) {
        return true;
      }

      return false;
    }),
});

const fairUsageSchema = {
  usage: yup
    .array()
    .min(1, 'Please agree to our fair usage policy')
    .required(),
};

const termsSchema = {
  terms: yup
    .array()
    .min(1, 'Please agree to our terms and conditions')
    .required(),
};

const codeSchema = {
  code: yup
    .string()
    .required(),
};

const resetPasswordValidator = (
  setPasswordValidationErrors?: SetPasswordValidationErrors,
) => yup.object({
  ...emailSchema,
  ...codeSchema,
  ...passwordSchema(setPasswordValidationErrors),
});

const newUserValidator = (
  setPasswordValidationErrors: SetPasswordValidationErrors,
) => yup.object({
  ...nameSchema,
  ...emailSchema,
  ...passwordSchema(setPasswordValidationErrors),
  ...termsSchema,
  ...fairUsageSchema,
});

const passwordChallengeValidator = (
  setPasswordValidationErrors?: SetPasswordValidationErrors,
) => yup.object({
  ...emailSchema,
  oldPassword: yup.string(),
  newPassword: passwordSchema(setPasswordValidationErrors).password,
});

export { resetPasswordValidator, newUserValidator, passwordChallengeValidator };
