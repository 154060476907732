'use client';

import { useState } from 'react';
import { useFormik } from 'formik';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { CustomError } from 'common/lib/errors';
import Button from '@/src/components/Button/Button';
import FormikCheckbox from '@/src/components/Input/FormikCheckbox';
import FormikInput from '@/src/components/Input/FormikInput';
import GroundsureIoLogoFull from '@/src/components/Logos/GroundsureIoLogo/GroundsureIoLogoFull';
import StepChecker from '@/src/components/StepChecker/StepChecker';
import UnderlinedLink from '@/src/components/Typography/UnderlinedLink';
import { post } from '@/src/services/xhr';
import { newUserValidator } from '@/src/utils/validator';

type NewUserForm = {
  name: string,
  email: string,
  password: string,
  terms: string[],
  usage: string[],
  marketing_consent: string[]
};

export default function SignUp() {
  const [
    passwordChecklist,
    setPasswordChecklist,
  ] = useState<{ text: string, pass: boolean }[]>([]);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [serverForbiddenError, setServerForbiddenError] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const router = useRouter();

  const onSignUp = async (values: NewUserForm) => {
    setServerForbiddenError(null);
    setIsError(false);

    const {
      name, email, password, marketing_consent,
    } = values;

    try {
      await post('/api/auth/new-user', {
        name: name.trim(), email, password, marketing_consent: !marketing_consent.length,
      }, { cache: 'no-cache' });

      const auth = await signIn('credentials', {
        email,
        password,
        redirect: false,
        callbackUrl: '/',
      });

      if (!auth?.ok || auth?.error) {
        router.push('/login');
      }

      if (!auth?.error) {
        console.log('Auth success');
        router.push('/');
      }
    } catch (err) {
      const customError = err as CustomError;
      if (customError.httpCode === 403) {
        setServerForbiddenError(customError.message);
      } else {
        setIsError(true);
      }
      console.error('sign up error', err);
    }
  };

  const initialValues = {
    name: '',
    email: '',
    password: '',
    terms: [],
    usage: [],
    marketing_consent: [],
  };

  const newUserForm = useFormik<NewUserForm>({
    initialValues,
    onSubmit: onSignUp,
    validationSchema: newUserValidator(setPasswordChecklist),
    validateOnChange: true,
  });

  return (
    <>
      <div className="flex flex-col gap-4 px-10 py-6 w-full sm:max-w-[auto] sm:flex-row-reverse items-center">
        <Link className="w-fit h-fit ml-auto" href="/login">
          <Button
            className="w-fit h-fit ml-auto whitespace-nowrap"
            size="sm"
            type="button"
            styleType="outline"
            text="login"
          />
        </Link>
        <GroundsureIoLogoFull />
      </div>

      {isError && (
      <div className="bg-rose-100 py-3 w-full lg:pl-6">
        <span className="text-white text-xs sm:text-sm block text-center lg:text-left">Something went wrong try again.</span>
      </div>
      )}

      {serverForbiddenError && (
      <div className="bg-amber-60 py-3 w-full lg:pl-6">
        <span className="text-blue-100 text-xs sm:text-sm block text-center lg:text-left">{serverForbiddenError}</span>
      </div>
      )}

      <div className="mt-10 pb-10 flex flex-col items-center w-full max-w-[482px]">

        <h1 className="font-museo-700 text-3xl text-blue-100 mb-4 text-center">
          INTELLIGENCE
          {' '}
          <br />
          OPTIMISED
        </h1>
        <h2 className="text-xl text-blue-100 text-center mb-10">
          Sign up to start investigating for
          <br />
          free today
        </h2>

        <form
          onSubmit={newUserForm.handleSubmit}
          className="w-full px-10 flex flex-col items-center justify-center"
        >
          <FormikInput
            formik={newUserForm}
            className="mb-6"
            type="name"
            name="name"
            id="name"
            placeholder="Full name"
            required
            startIcon={(
              <Image
                src="/assets/icons/user.svg"
                width={24}
                height={24}
                alt="user icon"
              />
            )}
          />

          <FormikInput
            formik={newUserForm}
            className="mb-6"
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            required
            startIcon={(
              <Image
                src="/assets/icons/envelope-small.svg"
                width={24}
                height={24}
                alt="user icon"
              />
            )}
          />

          <FormikInput
            formik={newUserForm}
            className="mb-6"
            type={showPassword ? 'text' : 'password'}
            name="password"
            id="password"
            required
            placeholder="Password"
            onFocus={() => setPasswordFocused(true)}
            startIcon={(
              <Image
                src="/assets/icons/padlock.svg"
                width={24}
                height={24}
                alt="user icon"
              />
            )}
            endIcon={(
              <Image
                className="mr-4 ml-2 cursor-pointer"
                src={showPassword ? '/assets/icons/eye.svg' : '/assets/icons/eyeOff.svg'}
                alt="user"
                width={24}
                height={24}
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            )}
          />

          <StepChecker
            list={passwordChecklist}
            check={(
              <Image
                width={24}
                height={24}
                src="/assets/icons/check.svg"
                alt="valid"
              />
)}
            cross={(
              <Image
                width={24}
                height={24}
                src="/assets/icons/cross.svg"
                alt="not valid"
              />
)}
            isVisible={passwordFocused && passwordChecklist.length !== 0}
          />

          <FormikCheckbox
            className="mr-auto mb-4"
            formik={newUserForm}
            id="terms"
            name="terms"
          >
            <span className="text-sm text-blue-100 leading-5">
              Please read and agree to our
              {' '}
              <UnderlinedLink
                external
                href="https://www.groundsure.com/groundsure-standard-terms-and-conditions-april-2023/"
              >
                Terms and Conditions

              </UnderlinedLink>
              {' '}
              and
              {' '}
              <UnderlinedLink
                external
                href="https://www.groundsure.com/groundsure-privacy-policy-november-2022/"
              >
                Privacy Policy

              </UnderlinedLink>
            </span>
          </FormikCheckbox>

          <FormikCheckbox
            className="mr-auto mb-6"
            formik={newUserForm}
            id="usage"
            name="usage"
          >
            <span className="text-sm text-blue-100 leading-5">
              Please read and agree to our
              {' '}
              <UnderlinedLink
                external
                href="https://www.groundsure.com/groundsure-io-terms-of-use"
              >
                Fair Usage Policy
              </UnderlinedLink>
            </span>
          </FormikCheckbox>

          <Button
            type="submit"
            disabled={!newUserForm.dirty || !newUserForm.isValid || newUserForm.isSubmitting}
            text={newUserForm.isSubmitting ? 'Creating account...' : 'Create account'}
            className="w-full mb-6"
          />

          <span className="font-museo-300 text-sm text-blue-100 leading-5 mb-6">
            We&apos;ll keep you updated on product news and special offers.
            Using the contact details you provided, we&apos;ll send you occasional emails
            related to our products and services.
            <br />
            You can opt out of these communications at any time:
          </span>

          <FormikCheckbox
            className="mr-auto mb-6"
            formik={newUserForm}
            id="marketing_consent"
            name="marketing_consent"
          >

            <span className="font-museo-300 text-sm text-blue-100 leading-5">
              Opt out of product updates and offers
            </span>
          </FormikCheckbox>
        </form>
      </div>
    </>
  );
}
